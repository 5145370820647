<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h3 class="card-title">Danışan Formları</h3>
        <div>
          <router-link to="/profile/client/forms/add" class="btn btn-primary"
            >Yeni Ekle</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <div class="item2-gl">
          <div class="input-group mb-4">
            <input
              type="text"
              class="form-control"
              id="searchAppointment"
              placeholder="Formlarda Ara"
            />
            <button type="button" class="btn btn-primary">ARA</button>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card" v-for="(i, a) in list" :key="a">
                <div
                  class="
                    card-header
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <h3 class="card-title">{{ i.title }}</h3>
                  <div
                    class="input-group float-right"
                    style="width: fit-content"
                  >
                    <router-link
                      :to="'/profile/client/forms/update/' + i.id"
                      class="btn btn-primary"
                      >Düzenle</router-link
                    >
                    <button class="btn btn-primary" @click="remove(i.id,a)">
                      Sil
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center-block text-center">
          <Pagination
            :currentpage="currentpage"
            :pagesize="pagesize"
            :preload="'/profile/client/forms/page/'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  <style scoped>
.pdf {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 50px;
}
.pdf:hover {
  cursor: pointer;
  color: #bb7494;
}
</style>
  <script>
import Pagination from "../../../../components/Pagination.vue";
import Swal from "sweetalert2";
export default {
  created() {
    document.title = "Danışan Formları";
    let page = {
      begin: (this.currentpage - 1) * this.perpage,
      perpage: this.perpage,
    };
    this.$store.dispatch("clientFormList",page).then((value) => {
      this.list = value.list;
      this.totalsize = value.size;
      this.pagesize = value.pages;
      this.educationLoad = false;
    });
  },
  data() {
    return {
      list: [],
      selectedStatus: 0,
      totalsize: 0,
      pagesize: 0,
      perpage: 20,
      currentpage: 1,
      billLoad: false,
    };
  },
  methods: {
    statusChange(i) {
      this.selectedStatus = i;
      this.getLink();
    },
    paymentDirect(item) {
      this.$router.push("/profile/billing/payment/" + item.billing_id);
    },
    billingDetail(item) {
      this.$router.push("/profile/billing/payment-detail/" + item.billing_id);
    },
    remove(id,i) {
      Swal.fire({
        title: "Danışan formu silinecek?",
        text: "Bu işlem geri dönüşü olmayan sonuçlar ortaya çıkarabilir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sil",
        cancelButtonText: "İptal",
      }).then((result) => {
        if (result.isConfirmed) {
          /**Listeden sil */
          this.$store.dispatch("clientFormRemove", {id:id}).then((value) => {
            if(value.type=='success'){
              this.list.splice(i, 1);
              this.$vToastify.success("Form başarılı bir şekilde silindi", "Başarılı!");
            } else {
              this.$vToastify.warning("İşlem yapılmış form silinemez", "Uyarı!");
            }
          });
          
        }
      });
    },
    getLink() {
      let info = {
        page: 1,
        perpage: this.perpage,
        selectedStatus: this.selectedStatus,
      };
      this.list = [];
      this.$store.dispatch("myBillings", info).then((value) => {
        this.list = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.billLoad = false;
      });
    },
  },
  components: { Pagination },
};
</script>